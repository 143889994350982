@import "../../../scss/general_styling";

.BuySellCoin {
  border-top: 1px solid $black-1;
  padding: 1em 0;

  .AmountInput {
    margin: 1em 0;
    .ant-input-affix-wrapper-lg {
      border: 2px solid #2a2c3e;
    }
    .ant-input-affix-wrapper-status-error {
      background-color: #2a2c3e !important;
      border: 2px solid #ff4d4f;
    }
  }

  h3 {
    @include primary-text($h4, $white, 400);
  }

  .FeeInfo {
    @include primary-text($body, $grey-4, 600);
    margin: 1em 0;
    .anticon {
      margin-right: 0.25em;
    }
  }

  .Alert {
    @include primary-text($body, #e8006f, 600);
    margin: 1em 0;
    .anticon {
      margin-right: 0.25em;
    }
  }

  hr {
    border: none;
    border-top: 1px solid $black-1;
  }

  .AdditionalInfo {
    margin: 0.5em 0;
    p {
      @include number-text($label, $grey-2, 400);
    }
  }

  .MaxButton {
    cursor: pointer;
    margin-left: 14px;
    background: #0f1227;
    color: $white;
    border-radius: 4px;
    border: 0;
    &:hover {
      background: $black-3;
    }
  }
}
