@import "../../../scss/general_styling";

.TotalBalance {
  background: #222433;
  border-radius: 8px;
  border: 1px solid $grey-5;
  padding: 1em;
  width: 32%;

  .Title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1em;
    img {
      width: 20px;
      height: 20px;
    }
    span {
      @include primary-text($body, #a0a0ac, 400);
      margin: 0;
      text-transform: uppercase;
    }
  }

  .ContentInfo {
    display: flex;
    align-items: center;
    img {
      width: 46px;
      height: 46px;
    }

    .Cointext {
      margin-left: 1em;
      .CoinTitle {
        @include primary-text($body, $white, 400);
        margin: 0;
        margin-bottom: 6px;
      }
      .CoinAmount {
        @include number-text($numbers, $white, 600);
        line-height: 1;
        margin-bottom: 0.25em;
      }
      .CoinAmountConverted {
        @include number-text($numbers, $white, 600);

        font-size: 1.125rem;
        font-weight: 400;
        margin: 0;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .TotalBalance {
    .Title {
      span {
        font-size: $label;
      }
    }
    .ContentInfo {
      img {
        width: 36px;
        height: 36px;
      }
      .Cointext {
        .CoinTitle {
          font-size: $label;
        }
        .CoinAmount {
          font-size: $h4;
        }
      }
    }
  }
}

@media only screen and (max-width: 425px) {
  .TotalBalance {
    width: 100%;
    margin-bottom: 1em;
  }
}
