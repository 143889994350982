@import "../../../scss/general_styling";

.custom-connect-card {
  @include buttonBase(0.5em 1.25em, 8px);
  cursor: unset;
  height: 50px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  outline: none;
  max-width: 200px;
  margin: auto !important;
  @include primary-text($body, $white, 600);
  background: $black-3;
  border: 2px solid $black-3;

  span {
    margin: 0 1em;
  }
}
